<template>
  <div class="container-fluid">
    <sideview
      :is-sidebar-active="sidebarActivated"
      :data="billing_info"
      @closeSidebar="toggleDataSidebar"
    />
    <b-col
      sm="12"
      md="6"
      class="mx-0 px-0"
    >
      <b-card
        class="w-100 mx-0 p-0"
      >
        <div class="d-flex justify-content-between my-2">

          <h4 class="font-weight-bolder mr-3">
            Billing Information
          </h4>

          <b-button
            type="filled"
            variant="warning"

            @click="sidebarActivated = true"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <div class="ml-2">
            <b-row
              v-if="billing_info.first_name !== undefined"
            >
              <p>{{ billing_info.first_name }} {{ billing_info.last_name }}</p>
            </b-row>
            <b-row>
              <p>{{ $store.state.auth.ActiveUser.company_name }}</p>
            </b-row>
            <b-row
              v-if="billing_info.address_1 !== undefined"
            >
              <p>{{ billing_info.address_1 }}</p>
            </b-row>
            <b-row
              v-if="billing_info.city !== undefined"
            >
              <span>{{ billing_info.city }}</span>
              <span v-if="billing_info.province !== undefined">, </span>
              <span>{{ billing_info.province }}</span>
              <span> {{ billing_info.postal_code }}</span>
            </b-row>
          </div>

          <div class="ml-2 ml-md-0 mr-md-2 mt-1 mt-md-0">
            <b-row
              v-if="billing_info.position_in_company !== undefined"
            >
              <p>{{ billing_info.position_in_company }}</p>
            </b-row>
            <b-row
              v-if="billing_info.email_address !== undefined"
            >
              <p>{{ billing_info.email_address }}</p>
            </b-row>
            <b-row
              v-if="billing_info.phone_number !== undefined"
            >
              <p>{{ billing_info.phone_number }}</p>
            </b-row>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-card>

      <vue-good-table
        v-model="selected_receipts"

        :columns="columns"
        :rows="receipts"
        :search-options="{
          enabled: false
        }"

        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="getStatusColor(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'files'">
            <b-button
              variant="warning"
              class="mr-3 mb-2"
              @click="openFile(tr.file_no, tr.type)"
            >Download PDF</b-button>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','20', '50', '100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

    </b-card>

  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { VueGoodTable } from 'vue-good-table'

import {
  BCard,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BFormSelect,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Sideview from './Sideview.vue'

export default {
  components: {
    VueGoodTable,
    BCard,
    BPagination,
    BButton,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BOverlay,
    BModal,
    BFormSelect,
    BFormCheckbox,
    BFormInvalidFeedback,
    VuePerfectScrollbar,
    Sideview,
  },
  data() {
    return {
      sidebarActivated: false,
      billing_info: {},
      show_bulk: true,
      isMounted: false,
      receipts: [],
      receipt_email: null,
      current_employer: '',
      action_view: 'dark',
      action_text: 'Actions',
      action_icon: 'info_outline',

      selected_receipts: [],
      selected_jobs: [],
      selected_job: {},
      action_bar: false,
      itemsPerPage: {
        receipts: 4,
      },
      title: 'View Job',
      action: 'insert',

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Done On',
          field: 'paid_on',
        },
        {
          label: 'Description',
          field: 'paid_for',
        },
        {
          label: 'Total Amount',
          field: 'amount',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Files',
          field: 'files',
        },

      ],
      rows: [],
      searchTerm: '',

      isLoading: true,
    }
  },

  computed: {
    currentAppPage() {
      if (this.isMounted) {
        return this.$refs.table_receipts.currentx
      }
      return 0
    },

    queriedAppItems() {
      return this.$refs.table_receipts
        ? this.$refs.table_receipts.queriedResults.length
        : this.receipts.length
    },
    isFormValid() {
      return (
        !this.errors.any()
        && this.dataName
        && this.dataCategory
        && this.dataPrice > 0
      )
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
  },

  created() {
    this.isLoading = true
    // this.getAllReceipts()
    this.getBillingInfo()
  },
  methods: {
    toggleDataSidebar() {
      this.sidebarActivated = false
    },
    getStatusColor(num) {
      if (num === 'Paid') return 'success'
      if (num === 'Received') return 'dark'
      if (num === 'Pending') return 'warning'
      if (num === 'Applied') return 'danger'
      return 'primary'
    },
    async getBillingInfo() {
      await this.$http
        .get('/api/billing-info')
        .then(response => {
          if (response.data.success) {
            const results = response.data.output
            this.billing_info = results
          }
        })
        .catch(error => {})
    },
    async openFile(file_no, filename) {
      this.$http
        .get(`/api/files/${filename}-${file_no}.pdf`, { responseType: 'blob' })
        .then(response => {
          if (response.data.errors) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Download Notification',
                  icon: 'AlertCircleIcon',
                  text: 'File not available yet.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            const header = response.headers
            const file_type = header['content-type']

            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${filename}-${file_no}.pdf`) // or any other extension
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch(error => {})
    },
    async getAllReceipts() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      await this.$http
        .get('/api/payments')
        .then(response => {
          const records = response.data.output
          this.receipts = records
          this.isLoading = false
        })
        .catch(error => {})
    },
    mounted() {
      this.isMounted = true
    },
  },
}
</script>

<style lang="scss">

</style>
